/* index.css */
body {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  color: #333;
}

.App {
  text-align: center;
  padding: 20px;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

.login-container {
  max-width: 300px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
}

.login-container form {
  display: flex;
  flex-direction: column;
}

.login-container label {
  margin-bottom: 5px;
}

.login-container input {
  margin-bottom: 15px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.login-container button {
  padding: 10px;
  background-color: #282c34;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.login-container button:hover {
  background-color: #3f51b5;
}

.login-container p {
  margin-top: 10px;
}

.login-container a {
  color: #3f51b5;
  text-decoration: none;
}

.login-container a:hover {
  text-decoration: underline;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: auto;
  background-color: #282c34;
  color: #ffffff;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  padding-top: 1em;
  padding-bottom: 1em;
}

.top1 {
  margin-top: 1em;
}